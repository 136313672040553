import React, { Component } from 'react';
import "./ReferralManagemet.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import Global from "../../lib/Global";
import moment from "moment";
import clsx from 'clsx';
import { makeStyles } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseRounded from '@mui/icons-material/CloseRounded';
import DataTable from 'react-data-table-component';
import { FormControlLabel, FormGroup, Paper, Switch, TextField } from '@mui/material';
import md5 from "md5";
import ExportRefererUser from './ExportRefererUser';
import { Add } from '@mui/icons-material';

class CustomerService extends Component {
    state = { 
        columns : [
            {
                name: "Title",
                selector: row => row.title,
                sortable: true
            },
            {
                name: "Status",
                selector: row => row.status,
                sortable: true
            },
            {
                name: "Content",
                selector: row => row.content,
                sortable: true
            },
            {
                name: "WhatsApp",
                selector: row => row.number_wa,
                sortable: true
            },
            {
                name: "Telegram",
                selector: row => row.number_telegram,
                sortable: true
            },
            {
                name: "Link Telegram",
                selector: row => row.link_telegram,
                sortable: true
            },
            // {
            //     name: "Logo",
            //     selector: row => <img src={row.logo} alt={"logo " + row.name} style={{maxWidth: "30px"}} />,
            //     sortable: false
            // },
            {
                name: "Created",
                selector: row => Global.formatDateTimeToStrDateTime24(row.created_at),
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = Global.formatDateTimeToStrFormatted(rowA.created_at, "x");
                    const b = Global.formatDateTimeToStrFormatted(rowB.created_at, "x");

                    return parseInt(a) > parseInt(b) ? 1 : -1

                }
            },
            {
                name: "Updated",
                selector: row => Global.formatDateTimeToStrDateTime24(row.updated_at),
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = Global.formatDateTimeToStrFormatted(rowA.updated_at, "x");
                    const b = Global.formatDateTimeToStrFormatted(rowB.updated_at, "x");

                    return parseInt(a) > parseInt(b) ? 1 : -1

                }
            },
            {
                name: "Action",
                selector: row => row.action
            }
        ],
        data : [], 
        top: false,
        left: false,
        bottom: false,
        right: false,
        selectedCs : {
            _id: "",
            category: "",
            content: "",
            number_telegram: null,
            number_wa: "",
            status: "active",
            title: "",
            created_at: "",
            updated_at: "",
            link_telegram: ""
        },
        //all, owner store, cashier
        role: "all",
        isLoading: false
    }

    defaultSelectedCs = () => {
        return {
            _id: "",
            category: "",
            content: "",
            number_telegram: null,
            number_wa: "",
            status: "active",
            title: "",
            created_at: "",
            updated_at: "",
            link_telegram: ""
        };
    }

    options = {
        filterType: 'checkbox',
    };

    loadCsList = (role) => {

        this.setState({isLoading: true}, () => {
            axios
          .get(
            Api.csList(),
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("QRIS_PROVIDERS", response.data);
            const responseData = response.data;
            // const userDatas = [];
            
            responseData.contact_person.forEach(element => {
                // element.created_at =  moment(element.created_at).format("YYYY-MM-DD");
                element.action = this.action(element);
                // const userData = {
                //     nama: element.nama, 
                //     no_ktp: element.no_ktp, 
                //     tempat_lahir: element.tempat_lahir,
                //     tanggal_lahir: element.tanggal_lahir,
                //     referal_code: element.referal_code,
                //     downline: element.downline,
                //     created_at: moment(element.created_at).format("YYYY-MM-DD"), 
                //     action: this.action(element)
                // };
                // userDatas.push(userData);
            });

            this.setState({data: responseData.contact_person, isLoading: false});
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
      };

    createQrisProvider = (role) => {

        const params = {
            name: this.state.selectedUser.name,
            logo: this.state.selectedUser.logo,
            is_bank: this.state.selectedUser.is_bank
        };
    
        console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
        // return;
    
        this.setState({isLoading: true}, () => {
            axios
          .post(
            Api.qrisProviderAdd(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            console.log("CREATE_QRISPROVIDER", response.data);
            
            this.setState({isLoading: false, right: false, selectedUser: this.defaultSelectedCs}, () => {
                this.loadCsList()
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
      };

        updateCs = () => {

            const params = {
                category: "contact_person",
                content: this.state.selectedCs.content,
                number_telegram: this.state.selectedCs.number_telegram,
                number_wa: this.state.selectedCs.number_wa,
                status: this.state.selectedCs.status,
                title: this.state.selectedCs.title
            };

            console.log("PARAMS USERS", params, "HEADER", Api.getRequestHeader());
            // return;
        
            this.setState({isLoading: true}, () => {
                axios
            .put(
                Api.csUpdate(this.state.selectedCs._id),
                params,
                Api.getRequestHeader()
            )
            .then((response) => {
                console.log("UPDATE_QRISPROVIDER", response.data);
                
                this.setState({isLoading: false, right: false, selectedCs: this.defaultSelectedCs}, () => {
                    this.loadCsList();
                });
                
            })
            .catch((error) => {
                console.error("There was an error!", error);
                this.setState({isLoading: false});
            });
            });
        
        };

      componentDidMount() {
          this.loadCsList();
      }

      toggleDrawer = (open, userInfo) => (event) => {
          console.log(userInfo);
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        
        let defaultInfo = this.defaultSelectedCs();

        this.setState({right: open, selectedCs: !open ? defaultInfo : userInfo });
      };

    action = (csInfo) => {      
        return (
            <div>
                <span className="btn btn-primary" onClick={this.toggleDrawer(true, csInfo)}>Edit</span>&nbsp;
                {/* <span className="btn btn-primary" onClick={this.toggleDrawer(true, userInfo)}>Gen Code</span> */}
            </div>
        );
    }

    handleTitleChange = (event) => {
        let selectedCsData = this.state.selectedCs;
        selectedCsData.title = event.target.value;

        this.setState({selectedCs : selectedCsData}, () => {
            // console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }
    
    handleContentChange = (event) => {
        let selectedCsData = this.state.selectedCs;
        selectedCsData.content = event.target.value;

        this.setState({selectedCs : selectedCsData}, () => {
            // console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }

    handleWhatsAppChange = (event) => {
        let selectedCsData = this.state.selectedCs;
        selectedCsData.number_wa = event.target.value;

        this.setState({selectedCs : selectedCsData}, () => {
            // console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }

    handleTelegramChange = (event) => {
        let selectedCsData = this.state.selectedCs;
        selectedCsData.number_telegram = event.target.value;

        this.setState({selectedCs : selectedCsData}, () => {
            // console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }
    
    handleLinkTelegramChange = (event) => {
        let selectedCsData = this.state.selectedCs;
        selectedCsData.link_telegram = event.target.value;

        this.setState({selectedCs : selectedCsData}, () => {
            // console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }

    handleStatusChange = (event) => {
        let selectedCsData = this.state.selectedCs;
        selectedCsData.status = event.target.checked ? "active" : "inactive";

        this.setState({selectedCs : selectedCsData}, () => {
            // console.log("SELECTEDUSER : ", this.state.selectedUser);
        });
    }

    list(anchor,){
        return (
        <div className="info" role="presentation"
        //   onClick={this.toggleDrawer(anchor, false)}
        //   onKeyDown={this.toggleDrawer(anchor, false)}
        >
          <div className="container">
              <div className="row">
                    <div className="col-11">
                        <h1>Customer Service</h1>
                    </div>
                   
                  <div className="col-1">
                      <CloseRounded onClick={() => {this.toggleDrawer(false, {})}} />
                  </div>
              </div>
              {/* <br /><br />
                <div className="text-center">
                    <img src="https://picsum.photos/id/1/200/300" className="avatar" alt=""/>
                </div> */}
              <br />
                <div className="row row-padding">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleTitleChange} 
                            id="txt_title" 
                            label="Title" 
                            fullWidth 
                            variant="outlined" 
                            value={this.state.selectedCs.title} 
                            size='small'
                        />
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleContentChange} 
                            id="txt_content" 
                            label="Content" 
                            fullWidth 
                            variant="outlined" 
                            value={this.state.selectedCs.content} 
                            size='small'
                        />
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleWhatsAppChange} 
                            id="txt_wa" 
                            label="WhatsApp" 
                            fullWidth 
                            variant="outlined" 
                            value={this.state.selectedCs.number_wa} 
                            size='small'
                        />
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleTelegramChange} 
                            id="txt_telegram" 
                            label="Telegram" 
                            fullWidth 
                            variant="outlined" 
                            value={this.state.selectedCs.number_telegram} 
                            size='small'
                        />
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-12">
                        <TextField 
                            onChange={this.handleLinkTelegramChange} 
                            id="txt_link_telegram" 
                            label="Link Telegram" 
                            fullWidth 
                            variant="outlined" 
                            value={this.state.selectedCs.link_telegram} 
                            size='small'
                        />
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-12">
                        <FormGroup>
                            <FormControlLabel onChange={this.handleStatusChange} control={<Switch checked={this.state.selectedCs.status === "active"}/>} label={this.state.selectedCs.status === "active" ? "Active" : "Inactive"} />
                        </FormGroup>
                    </div>
                </div>
                
                <hr />
                <div className="text-center">
                    <span className="btn btn-primary" onClick={() => this.updateCs()}>Update</span>
                </div>
          </div>
          <br />
        </div>
      );
    }

    getFilterClasses = (role) => {
        let classes = "col-4";
        if(role === this.state.role){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }

    handleChangeFilter = (role) => {
        console.log("ROLE : ", role);
        this.setState({role: role});
        this.loadCsList(role);
    }

    render() { 
        return ( 
            <div>
                {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                  <Drawer anchor={anchor} open={this.state[anchor]} 
                    onClose={this.toggleDrawer(false,{})}
                    >
                    {this.list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}

                <div className="container-fluid no-padding">
                    {/* <div className="container-fluid header-page">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 text-right">
                                <span className="btn btn-primary" onClick={this.toggleDrawer(true, {})}><Add /> Add</span>
                            </div>
                        </div>
                </div> */}

                    <div className="container-fluid dashboard-content">
                        {/* <div className="row">
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                                <small>Tampilkan hasil untuk :</small> 
                                <br />
                                {this.state.role === "all" ? "Semua" : this.state.role === "owner store" ? "Pemilik" : "Kasir"}
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-8 col-xs-12">
                                <div className="row filter-container">
                                    <div onClick={() => this.handleChangeFilter("all")} className={this.getFilterClasses("all")}>
                                        Semua
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("owner store")} className={this.getFilterClasses("owner store")}>
                                        Pemilik
                                    </div>
                                    <div onClick={() => this.handleChangeFilter("cashier")} className={this.getFilterClasses("cashier")}>
                                        Kasir
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <br /> */}
                        <DataTable 
                            title="" 
                            data={this.state.data} 
                            columns={this.state.columns} 
                            options={this.options} 
                            // selectableRows
                            pagination
                            progressPending={this.state.isLoading}
                            // progressComponent={<div>Loading</div>}
                        />

                    </div>
                </div>
            </div>
        );
    }
}
 
export default CustomerService;