import React, { Component } from 'react';
import "./DashBoardStoreManagement.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import Global from "../../lib/Global";
import moment from 'moment-timezone';
import { Drawer, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import ExportSurvey from './ExportSurvey';
import htmlParser from "html-react-parser";

class Survey extends Component {
    state = { 
        surveyList: [],
        bottom: false,
        selectedSurvey: null,
        surveyAsnwers: [],
        isLoadingSurvey: false,
        isLoadingAnswers: false
    }


    getFilterClasses = (type) => {
        let classes = "col-6";
        if(type === this.state.type){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }
    
    loadSurveyLists = () => {

        const params = {
            filter: {},
            sort: {created_at: 1},
            limit: 100
        };

        axios.post(
            Api.surveyList(),
            params,
            Api.getRequestHeader()
          ).then((response) => {
            let list = response.data.data;
            this.setState({surveyList: list}, () => {});
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
        });
    }

    loadSurveyAnswersBySurveyId = () => {

        const params = {
            filter: {
                survey_id: this.state.selectedSurvey?._id
            },
            sort: {_id: 1},
            limit: 100
        };

        this.setState({isLoadingAnswers: true}, () => {
            axios.post(
                Api.surveyListAnswers(),
                params,
                Api.getRequestHeader()
            ).then((response) => {
                let list = response.data.data;

                list.forEach(row => {
                    row.answer.forEach(ans => {
                        ans.formattedAnswers = "<ul>";
                        ans.answers.forEach((item, index) => {
                            // ans.formattedAnswers += item.choice_text + (index < ans.answers.length-1 ? "<br />" : "");
                            ans.formattedAnswers += `<li>` + item.choice_text + `</li>`;
                        });
                        ans.formattedAnswers += "</ul>";
                    });
                });

                this.setState({surveyAsnwers: list, isLoadingAnswers: false}, () => {});
            })
            .catch((error) => {
                console.error("There was an error!", error);
                this.setState({isLoadingAnswers: false});
            });
        });
        
    }

    toggleDrawer = (open, survey)=> {
        console.log("KLIKED");
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //   return;
        // }
        
        // let defaultInfo = this.defaultSelectedUser();

        this.setState({bottom: open, selectedSurvey: survey}, () => {
            if(open){
                this.loadSurveyAnswersBySurveyId(survey._id);
            }else{
                this.setState({surveyAsnwers: [], selectedSurvey: null});
            }
            
        });
    };

    componentDidMount(){
        this.loadSurveyLists();
    }

    render(){
        return(
            <div className="container-fluid no-padding">
                {['left', 'right', 'top', 'bottom'].map((anchor) => (
                    <React.Fragment key={anchor}>
                    <Drawer anchor={anchor} open={this.state[anchor]} 
                        onClose={() => this.toggleDrawer(false,{})}
                        >
                        <div className="container-fluid" style={{padding: "10px", minHeight: "80vh"}}>
                            {/* <div className="row">
                                <div className="col-11"></div>
                                <div className="col-1 text-center">
                                    <div className="btn btn-sm btn-danger" onClick={() => this.toggleDrawer(false)}>X</div>
                                </div>
                            </div>
                            <br /> */}
                            {/* <Divider style={{marginTop: "10px", marginBottom: "10px"}} /> */}
                            <TableContainer component={Paper}>
                                <Paper style={{marginTop: "10px", marginBottom: "10px", backgroundColor: "#dededd", padding: "5px"}}>
                                    <div className="row">
                                        <div className="col-9">
                                            <strong>Nama Survey : </strong><strong>{this.state.selectedSurvey?.title}</strong>
                                        </div>
                                        <div className="col-2 text-center">
                                            {/* <ExportSurvey dataSurvey={this.state.selectedSurvey} dataAnswers={this.state.surveyAsnwers} /> */}
                                            {/* <div className="btn btn-sm btn-primary btn-block" onClick={() => {}}>Export Excel</div> */}
                                        </div>
                                        <div className="col-1 text-center">
                                            <div className="btn btn-sm btn-danger" onClick={() => this.toggleDrawer(false)}>X</div>
                                        </div>
                                    </div>
                                </Paper>
                                    {
                                        this.state.isLoadingAnswers && (<LinearProgress />)
                                    }
                                {/* <br /> */}
                                <Table size='small'>
                                    <TableHead>
                                        <TableCell width={"130px"} align='center'><strong>Waktu</strong></TableCell>
                                        <TableCell align='center'><strong>Nama</strong></TableCell>
                                        {
                                            this.state.selectedSurvey?.questions?.map(question => {
                                                return (
                                                    <TableCell width={"200px"} align='center'><strong>{question?.questionText}</strong></TableCell>
                                                );
                                            })
                                        }
                                        
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.surveyAsnwers?.map(survey => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{Global.formatDateTimeToStrDateTime24(survey.created_at)}</TableCell>
                                                        <TableCell>{survey.user_name}</TableCell>
                                                        {
                                                            survey.answer.map(answer => {
                                                                return (
                                                                    // <TableCell>{answer.answers[0].choice_text}</TableCell>
                                                                    <TableCell>{htmlParser(answer.formattedAnswers)}</TableCell>
                                                                );
                                                            })
                                                        }
                                                        
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                    <TableFooter style={{padding: "5px", textAlign: "right"}}>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </Drawer>
                    </React.Fragment>
                ))}
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead>
                            <TableCell width={"20%"} align='center'><strong>Nama Survey</strong></TableCell>
                            <TableCell width={"20%"} align='center'><strong>Tanggal Mulai</strong></TableCell>
                            <TableCell width={"20%"} align='center'><strong>Tanggal Berakhir</strong></TableCell>
                            <TableCell width={"20%"} align='center'><strong>Jumlah Participant</strong></TableCell>
                            <TableCell width={"10"} align='center'><strong>Status</strong></TableCell>
                            <TableCell width={"10%"} align='center'><strong></strong></TableCell>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.surveyList.map(survey => {
                                    return (
                                        <TableRow>
                                            <TableCell>{survey.title}</TableCell>
                                            <TableCell align='center'>{Global.formatDateTimeToStrDateTime24(survey.start_date)}</TableCell>
                                            <TableCell align='center'>{Global.formatDateTimeToStrDateTime24(survey.end_date)}</TableCell>
                                            <TableCell align='center'>{survey.count_answer}</TableCell>
                                            <TableCell align='center'>{survey.status}</TableCell>
                                            <TableCell align='center'>
                                                <Tooltip title="Lihat hasil survey">
                                                    <div className="btn btn-link btn-sm" onClick={() => this.toggleDrawer(true, survey)}>
                                                        <MoreVert />
                                                    </div>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                        <TableFooter style={{padding: "5px", textAlign: "right"}}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableFooter>
                    </Table>
                </TableContainer>
                
            </div>
        );
    }
}
 
export default Survey;