import { CloseRounded, Delete, Edit } from '@mui/icons-material';
import { Divider } from '@mui/material';
import React, { Component } from 'react';
import moment from "moment";
import axios from "axios";
import Api from "../../lib/Api";
import Global from "../../lib/Global";

class StoreTransactionList extends Component {
    state = { 
        storeTransactions: []
     } 


    loadTransactions = () => {
        
        let params = {
            filter: {store_id: this.props.storeInfo.id},
            // filter: {},
            sort:{created_at: -1},
            limit:100
        };

    
        console.log("PARAMS STORES", params, "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        axios
        .post(
            Api.storeTransactionList(),
            params,
            Api.getRequestHeader()
        )
        .then((response) => {
            console.log("STORE_TRANS_RESPONSE", response.data);
            const responseData = response.data;
            // const userDatas = [...this.state.data];
            const listTrans = [];
            
            responseData.list_transactions.forEach(element => {
                listTrans.push(element);
            });

            this.setState({storeTransactions: listTrans});
        
        })
        .catch((error) => {
            console.error("There was an error!", error);
        });
    };

    componentDidMount(){
        this.loadTransactions();
    }
    
    render() { 
        return (
            <div className='container-fluid' style={{minWidth:"500px", maxWidth: "550px", padding: "10px"}}>
                
                {
                    this.props.showTitle !== false ? (
                        <>
                            <h3>List Transaksi Toko</h3>
                            {/* <br /> */}
                            <strong>
                                {this.props.storeInfo.nama_toko}
                            </strong>
                            <br /><br />
                            
                        </>
                    ) : (<></>)
                }

                <strong>Total : {this.state.storeTransactions.length} transaksi</strong>
                <br />
                <br />
                
                <div className="container border">
                    <div className="row" style={{paddingTop: "10px", paddingBottom: "10px"}}>
                        <div className="col-3" style={{fontSize: "11px", fontWeight: "bold"}}>Tanggal</div>
                        <div className="col-3" style={{fontSize: "11px", fontWeight: "bold"}}>Metode</div>
                        <div className="col-3" style={{fontSize: "11px", fontWeight: "bold"}}>Nilai Transaksi</div>
                        <div className="col-3" style={{fontSize: "11px", fontWeight: "bold"}}>Download</div>
                    </div>
                    <Divider />
                {
                    this.state.storeTransactions.map((row) => (
                        <div style={{marginBottom: "0px"}}>
                            <div className="row" style={{paddingTop: "5px"}}>
                                <div className="col-3 text-secondary" style={{fontSize: "12px", paddingTop: "5px"}}>
                                    {Global.formatDateTimeToStrDateTime24(row.created_at)}
                                </div>
                                <div className="col-3" style={{fontSize: "11px", paddingTop: "5px"}}>
                                    <span>{row.metode}</span>
                                </div>
                                <div className="col-3" style={{fontSize: "11px", paddingTop: "5px"}}>
                                    <span>Rp{row.total_harga_setelah_diskon.toLocaleString('id-ID', { maximumSignificantDigits: 2 })}</span>
                                </div>
                                <div className="col-3">
                                    <a href={row.url} target="_blank" className="btn btn-block btn-link btn-sm">
                                        Receipt
                                    </a>
                                </div>
                            </div>
                            <Divider style={{marginTop: "5px", marginBottom: "5px"}} />
                        </div>
                    ))
                }

                {
                    this.state.storeTransactions.length <= 0 ? 
                        (
                            <div className='text-center'>
                                <span style={{padding: "10px"}}>Belum ada transaksi</span>
                            </div>
                        ) : (<></>)
                }

                    
                </div>
            </div>
        );
    }
}
 
export default StoreTransactionList;