import { CloseRounded } from '@mui/icons-material';
import { Button, Chip, Divider } from '@mui/material';
import moment from 'moment';
import React, { Component } from 'react';
import StoreTransactionList from '../DashboardStoreManagement/StoreTransactionList';
import Global from "../../lib/Global";

class UserInfo extends Component {
    state = { 
        selectedStore: null
     } 
    render() { 
        return JSON.stringify(this.props.selectedUser) === "{}" ? (<></>) : (
            <div className="info" role="presentation">
                <div className="container">
                    <div className="row">
                        <div className="col-10">
                            <h3>Detail Informasi</h3>
                        </div>
                        <div className="col-2">
                            <Button size='small' variant='contained' color='error' onClick={this.props.onCloseDrawer}>
                                <CloseRounded />
                            </Button>

                        </div>
                    </div>
                    <br />
                    {/* <br /> */}
                    <div className="text-center">
                        <img src= {this.props.selectedUser.avatar} style={{maxWidth: "100px", maxHeight: "100px"}} width={"50px"} height={"50px"} className="avatar" alt=""/>
                    </div>
                    <br />
                    <div className="row row-padding">
                        <div className="col-4">Nama</div>
                        <div className="col-8 text-right">
                            <strong>{this.props.selectedUser.fullname}</strong>
                        </div>
                    </div>
                    <div className="row row-padding">
                        <div className="col-4">Id User</div>
                        <div className="col-8 text-right">
                            <strong>{this.props.selectedUser.ussd}</strong>
                        </div>
                    </div>
                    <div className="row row-padding">
                        <div className="col-4">Phone Number</div>
                        <div className="col-8 text-right">
                            <strong>{this.props.selectedUser.ussd}</strong>
                        </div>
                    </div>
                    <div className="row row-padding">
                        <div className="col-4">Tipe Akun</div>
                        <div className="col-8 text-right">
                            <strong>{this.props.selectedUser.role}</strong>
                        </div>
                    </div>
                    <div className="row row-padding">
                        <div className="col-4"></div>
                        <div className="col-8 text-right">
                        <strong>{this.props.selectedUser.store[0].nama_toko}</strong> - {this.props.selectedUser.store[0].jenis_usaha}
                        </div>
                    </div>
                    <div className="row row-padding">
                        <div className="col-4">Login Terakhir</div>
                        <div className="col-8 text-right">
                            <strong>{Global.formatDateTimeToStrDateTime24(this.props.selectedUser.created_at)}</strong>
                        </div>
                    </div>

                    <div className="text-center">
                        <br />
                        <span className="btn btn-primary btn-rounded">
                            Non Aktifkan
                        </span>
                    </div>
                </div>
                <Divider style={{marginTop: "20px", marginBottom:"20px"}} />
                <div className="container">

                    <h4>List Toko</h4>

                    {this.props.selectedUser?.store?.map(store => {
                        // return <div className="badge badge-pill badge-secondary">wkwkwkw</div>
                        return <Chip color={store.id === this.state.selectedStore?.id ? "primary" : "secondary"} label={store.nama_toko} onClick={() => {this.setState({selectedStore: store})}} />
                    })}

                    {
                        this.state.selectedStore !== null ? (
                            <StoreTransactionList storeInfo={this.state.selectedStore} showTitle={false}/>
                        ) : (<></>)
                    }

                </div>
            </div>
        );
    }
}
 
export default UserInfo;