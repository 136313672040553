import React, { Component } from 'react';
import "./ReferralManagemet.css";
// import MUIDataTable from "mui-datatables";
import axios from "axios";
import Api from "../../lib/Api";
import Global from "../../lib/Global";
import moment from "moment";
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { Alert, ButtonGroup, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Input, InputAdornment, InputLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';
import { toDataURL } from "qrcode";
import shortid from 'shortid';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import VoucherPdf from './VoucherPdf';

class VoucherDiscount extends Component {
    MODE_FORM = "FORM";
    MODE_LIST_USER = "LIST_USER";
    state = { 
        columns : [
            {
                name: "Kode Gift Card",
                selector: row => row.id,
                sortable: true
            },
            {
                name: "Nama Gift Card",
                selector: row => row.username,
                sortable: true
            },
            {
                name: "Periode",
                selector: row => row.created_at_formatted,
                sortable: true,
                sortFunction: (rowA, rowB) => {
                    const a = moment(rowA.created_at).toDate();
                    const b = moment(rowB.created_at).toDate();

                    return a > b ? 1 : -1

                }
            },
            {
                name: "Jenis",
                selector: row => row.metode,
                sortable: true
            },
            {
                name: "Jumlah",
                selector: row => row.foto !== null && row.foto !== undefined && row.foto.length > 0 ? <span>Ada</span> : <span>Belum ada</span>,
                sortable: true
            },
            
            {
                name: "Status",
                selector: row => this.badgeStatus(row.status),
                sortable: true
            },
            {
                name: "Action",
                // selector: row => row.photo_url !== undefined && row.photo_url !== null && row.photo_url.length > 0 ? row.action : <></>
                selector: row => row.action
            }
        ],
        data : [], 
        top: false,
        left: false,
        bottom: false,
        right: false,
        selectedVoucher : null,
        //all, owner store, cashier
        status: "all",
        isLoading: false,
        keyword: "",
        showFormRejection: false,
        errorMessage: "",
        expandedActionId: "",
        drawerMode: "",
        errorMessage: "",
        showQr: false,
        qr: null
    }

    defaultSelectedVoucher = () => {
        return {
            _id: "",
            voucher_code: "",
            nama: "",
            potongan: 0,
            kuota: 0,
            start_date: "",
            end_date: "",
            min_trans: 0,
            status: "",
            id: "",
            minimalTransaksiChecked: false,
        };
    }

    options = {
        filterType: 'checkbox',
    };

    STATUS_ACTIVE = "active";
    STATUS_INACTIVE = "inactive";
    STATUS_SOLD_OUT = "soldout";

    badgeStatus = (status) => {
        if(status === this.STATUS_ACTIVE){
            return (<div className="badge badge-success">Aktif</div>);
        }
        if(status === this.STATUS_SOLD_OUT){
            return (<div className="badge badge-success">Habis</div>);
        }
        if(status === this.STATUS_INACTIVE){
            return (<div className="badge badge-success">Tidak Aktif</div>);
        }
    }

    loadListVoucher = () => {

        let params = {
            // filter:{
                // photo_url : {$eq: "", $exists:true}
                // status: {$eq: "waiting verification"}
            // },
            // filter: {},
            sort  : {created_at: -1},
            // sort  : {foto: -1, created_at: -1},
            limit : 100
        };

        if(this.state.status !== "all" && this.state.status !== this.STATUS_SOLD_OUT){
            params.filter = {
                status: this.state.status
            }
        }
        else{
            params.filter = {}
        }

        if(this.state.keyword.length > 0){
            params.filter.nama = {
                $regex: this.state.keyword,
                $options: "i"
            }
        }
    
        console.log("PARAMS USERS", JSON.stringify(params), "HEADER", Api.getRequestHeader());
    
        // axios.get("https://randomuser.me/api/?results=20")
        this.setState({isLoading: true}, () => {
            axios
          .post(
            Api.listVoucher(),
            params,
            Api.getRequestHeader()
          )
          .then((response) => {
            const responseData = response.data;
            
            if(this.state.status === this.STATUS_SOLD_OUT){
                let voucherHabis = responseData.data.filter(item => item.totalPengguna >= item.kuota);
                this.setState({data: voucherHabis, isLoading: false});
            }else{
                this.setState({data: responseData.data, isLoading: false});
            }

          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
          });
        });
        
      };

    updateVoucher = () =>
    {

        let payload = {
            voucher_code: this.state.selectedVoucher.voucher_code,
            nama: this.state.selectedVoucher.nama,
            potongan: parseInt(this.state.selectedVoucher.potongan),
            kuota : parseInt(this.state.selectedVoucher.kuota),
            start_date: this.state.selectedVoucher.start_date,
            end_date: this.state.selectedVoucher.end_date,
            min_trans: this.state.selectedVoucher.minimalTransaksiChecked ? parseInt(this.state.selectedVoucher.min_trans) : 0,
            status: this.state.selectedVoucher.status
        };

        
        if(payload.voucher_code === undefined || payload.voucher_code === null || payload.voucher_code.length <= 0){
            this.setState({errorMessage: "Kode Gift belum diisi"});
            return;
        }

        if(payload.nama === undefined || payload.nama === null || payload.nama.length <= 0){
            this.setState({errorMessage: "Nama Gift belum diisi"});
            return;
        }

        if(payload.potongan === undefined || payload.potongan === null || payload.potongan <= 0){
            this.setState({errorMessage: "Potongan Gift belum diisi"});
            return;
        }

        if(payload.potongan === undefined || payload.potongan === null || payload.potongan <= 0){
            this.setState({errorMessage: "Potongan Gift belum diisi"});
            return;
        }

        if(payload.kuota === undefined || payload.kuota === null || payload.kuota <= 0){
            this.setState({errorMessage: "Kuota Gift belum diisi"});
            return;
        }

        if(payload.start_date === undefined || payload.start_date === null || payload.start_date.length <= 0){
            this.setState({errorMessage: "Tanggal mulai belum diisi"});
            return;
        }

        if(payload.end_date === undefined || payload.end_date === null || payload.end_date.length <= 0){
            this.setState({errorMessage: "Tanggal berakhir belum diisi"});
            return;
        }

        let startDate = moment(payload.start_date);
        let endDate = moment(payload.end_date);
        if(startDate.isAfter(endDate)){
            this.setState({errorMessage: "Tanggal mulai harus sebelum tanggal berakhir"});
            return;
        }

        if(this.state.minimalTransaksiChecked === true && payload.min_trans <= 0){
            this.setState({errorMessage: "Nilai minimal transaksi belum diisi"});
            return;
        }

        if(payload.min_trans > 0 && payload.potongan > payload.min_trans){
            this.setState({errorMessage: "Nilai potongan tidak boleh melebihi minimum transaksi"});
            return;
        }

        console.log("PAYLOAD", payload);
        
        axios.put(
            Api.updateVoucher(this.state.selectedVoucher.id),
            payload,
            Api.getRequestHeader()
          ).then((response) => {
            this.setState({isLoading: false, right: false, selectedVoucher: this.defaultSelectedVoucher}, () => {
                this.loadListVoucher()
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
        });
    }
    
    toggleStatusVoucher = (voucherInfo) =>
    {
        console.log("INFO", voucherInfo);
        let payload = {
            voucher_code: voucherInfo.voucher_code,
            nama: voucherInfo.nama,
            potongan: parseInt(voucherInfo.potongan),
            kuota : parseInt(voucherInfo.kuota),
            start_date: voucherInfo.start_date,
            end_date: voucherInfo.end_date,
            min_trans: parseInt(voucherInfo.min_trans),
            status: voucherInfo.status === "active" ? "inactive" : "active"
        };


        console.log("PAYLOAD", payload);
        
        axios.put(
            Api.updateVoucher(voucherInfo.id),
            payload,
            Api.getRequestHeader()
          ).then((response) => {
            this.setState({isLoading: false, right: false, selectedVoucher: this.defaultSelectedVoucher}, () => {
                this.loadListVoucher()
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
        });
    }
    
    addVoucher = () =>
    {

        let payload = {
            voucher_code: this.state.selectedVoucher.voucher_code,
            nama: this.state.selectedVoucher.nama,
            potongan: parseInt(this.state.selectedVoucher.potongan),
            kuota : parseInt(this.state.selectedVoucher.kuota),
            start_date: this.state.selectedVoucher.start_date,
            end_date: this.state.selectedVoucher.end_date,
            min_trans: this.state.selectedVoucher.minimalTransaksiChecked ? parseInt(this.state.selectedVoucher.min_trans) : 0,
            status: "active"
        };
        
        if(payload.voucher_code === undefined || payload.voucher_code === null || payload.voucher_code.length <= 0){
            this.setState({errorMessage: "Kode Gift belum diisi"});
            return;
        }

        if(payload.nama === undefined || payload.nama === null || payload.nama.length <= 0){
            this.setState({errorMessage: "Nama Gift belum diisi"});
            return;
        }

        if(payload.potongan === undefined || payload.potongan === null || payload.potongan <= 0){
            this.setState({errorMessage: "Potongan Gift belum diisi"});
            return;
        }

        if(payload.potongan === undefined || payload.potongan === null || payload.potongan <= 0){
            this.setState({errorMessage: "Potongan Gift belum diisi"});
            return;
        }

        if(payload.kuota === undefined || payload.kuota === null || payload.kuota <= 0){
            this.setState({errorMessage: "Kuota Gift belum diisi"});
            return;
        }

        if(payload.start_date === undefined || payload.start_date === null || payload.start_date.length <= 0){
            this.setState({errorMessage: "Tanggal mulai belum diisi"});
            return;
        }

        if(payload.end_date === undefined || payload.end_date === null || payload.end_date.length <= 0){
            this.setState({errorMessage: "Tanggal berakhir belum diisi"});
            return;
        }

        let startDate = moment(payload.start_date);
        let endDate = moment(payload.end_date);
        if(startDate.isAfter(endDate)){
            this.setState({errorMessage: "Tanggal mulai harus sebelum tanggal berakhir"});
            return;
        }

        if(this.state.minimalTransaksiChecked === true && payload.min_trans <= 0){
            this.setState({errorMessage: "Nilai minimal transaksi belum diisi"});
            return;
        }

        if(payload.min_trans > 0 && payload.potongan > payload.min_trans){
            this.setState({errorMessage: "Nilai potongan tidak boleh melebihi minimum transaksi"});
            return;
        }

        console.log("PAYLOAD", payload);
        // return;
        axios.post(
            Api.addVoucher(),
            payload,
            Api.getRequestHeader()
          ).then((response) => {
            this.setState({isLoading: false, right: false, selectedVoucher: this.defaultSelectedVoucher}, () => {
                this.loadListVoucher()
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
        });
    }

    getVoucherDetail = (voucherInfo) =>
    {

        // let payload = {
        //     voucher_code: this.state.selectedVoucher.voucher_code,
        //     nama: this.state.selectedVoucher.nama,
        //     potongan: parseInt(this.state.selectedVoucher.potongan),
        //     kuota : parseInt(this.state.selectedVoucher.kuota),
        //     start_date: this.state.selectedVoucher.start_date,
        //     end_date: this.state.selectedVoucher.end_date,
        //     min_trans: this.state.selectedVoucher.minimalTransaksiChecked ? parseInt(this.state.selectedVoucher.min_trans) : 0,
        //     status: "active"
        // };

        // console.log("PAYLOAD", payload);
        // return;
        axios.get(
            Api.getVoucherDetail(voucherInfo.id),
            Api.getRequestHeader()
          ).then((response) => {
            this.setState({isLoading: false, right: true, selectedVoucher: response.data.data, drawerMode: this.MODE_LIST_USER}, () => {
                console.log("VOUCHER_DETAIL", this.state.selectedVoucher);
                // this.toggleDrawer(true, this.state.selectedVoucher, this.MODE_LIST_USER);
                // this.setState({right: open, drawerMode: mode, selectedVoucher: !open ? defaultInfo : userInfo });
            });
            
          })
          .catch((error) => {
            console.error("There was an error!", error);
            this.setState({isLoading: false});
        });
    }

    

    componentDidMount() {
        this.setState({status: this.STATUS_ACTIVE, selectedVoucher: this.defaultSelectedVoucher()}, () => {
            this.loadListVoucher(this.STATUS_ACTIVE);
        });
    }

    toggleDrawer = (open, userInfo, mode) => (event) => {
        console.log(userInfo);
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        
        let defaultInfo = this.defaultSelectedVoucher();

        if(userInfo.min_trans !== null && userInfo.min_trans !== undefined && userInfo.min_trans > 0){
            userInfo.minimalTransaksiChecked = true;
        }

        this.setState({right: open, drawerMode: mode, selectedVoucher: !open ? defaultInfo : userInfo });
    };

    shareToWhatsApp = (voucherInfo) => {
        var windowReference = window.open();
        // moment.locale("it");
        let startDate = moment(voucherInfo.start_date).format("DD/MM/YYYY");
        let endDate = moment(voucherInfo.end_date).format("DD/MM/YYYY");

        let shareText = `Selamat, Anda menerima Gift Card PosSaku sebesar Rp ${voucherInfo.potongan}\n\n` + 
        `Kode ini berlaku selama ${startDate} hingga ${endDate}\n` + 
        
        `Kode Gift Card: *${voucherInfo.voucher_code}*\n\n` + 
        
        `Langkah-Langkah untuk mengunakan Gift Card:\n\n` +
        
        `1. Buka Aplikasi Possaku > Profil\n` +
        `2.Tekan tombol Upgrade Akun\n` +
        `3. Pilih Salah satu subscription & inputkan kode promo.\n` +
        `4. Tekan tombol Mulai Langganan,\n\n` +
        
        `Selamat Mengunakan PosSaku!\n` +
        
        `#MudahkanUsahaMu dengan PosSaku!`;

        var url = 'https://api.whatsapp.com/send?phone=' 
                    + '' 
                    + '&text=' 
                    + encodeURIComponent(shareText)

        windowReference.location = url;
    }

    handleShowQr = (voucherInfo) => {
        let options = {
            width: 200,
            margin: 2,
          }

        let qrValue = "";

        toDataURL(voucherInfo.voucher_code, options, (err, url) => {
            if (err) {
            console.error(err);
            return;
            }
            qrValue = url;
        });
        this.setState({selectedVoucher: voucherInfo, showQr: true, qr: qrValue}, () => {
            // ReactPDF.render(<PDFViewer><QrPdf /></PDFViewer>, document.getElementById('root'));
        });
    }

    handleHideQr = () => {
        this.setState({selectedVoucher: null, showQr: false});
    }

    handleDownloadQr = () => {
        const elm = document.createElement("a");
        elm.href = this.state.qr;
        elm.download = shortid();
        elm.click();
    }

    action = (voucherInfo) => {      
        return voucherInfo._id === this.state.expandedActionId ? 
        (    
            <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                    variant="contained"
                >
                    <Button color='secondary' key="collapse" style={{fontSize: 8}} onClick={() => this.setState({expandedActionId: ""})}><ArrowUpward style={{height: 10}} /></Button>
                    <Button key="one" style={{fontSize: 8}} onClick={this.toggleDrawer(true, voucherInfo, this.MODE_FORM)}>Ubah</Button>
                    <Button key="two" style={{fontSize: 8}} onClick={() => this.toggleStatusVoucher(voucherInfo)}>
                        {voucherInfo.status === "active" ? "Non Aktifkan" : "Aktifkan"}
                    </Button>
                    <Button key="three" style={{fontSize: 8}} onClick={() => this.getVoucherDetail(voucherInfo)}>Daftar Pengguna</Button>
                    {
                        voucherInfo.status === "active" ? (
                            <>
                                <Button key="four" style={{fontSize: 8}} onClick={() => this.shareToWhatsApp(voucherInfo)}>Share WA</Button>
                                <Button key="five" style={{fontSize: 8}} onClick={() => this.handleShowQr(voucherInfo)}>Tampilkan QR</Button>
                            </>
                        ) : (<></>)
                    }
                    
                </ButtonGroup>
        ) : 
        (
            <ButtonGroup
                orientation="vertical"
                aria-label="vertical contained button group"
                variant="contained"
            >
                <Button onClick={() => this.toggleActionButton(voucherInfo)} key="one" style={{fontSize: 10}}>Atur</Button>
            </ButtonGroup>
        );
    }

    handleKeywordChange = (event) => {
        this.setState({keyword: event.target.value}, ()=>{
            this.loadListVoucher(this.state.status);
        });
    }

    handleVoucherCodeChange = (event) => {
        let obEditVoucher = {...this.state.selectedVoucher};
        obEditVoucher.voucher_code = event.target.value;
        this.setState({selectedVoucher: obEditVoucher});
    }

    handleNamaChange = (event) => {
        let obEditVoucher = {...this.state.selectedVoucher};
        obEditVoucher.nama = event.target.value;
        this.setState({selectedVoucher: obEditVoucher});
    }

    handlePotonganChange = (event) => {
        let obEditVoucher = {...this.state.selectedVoucher};
        obEditVoucher.potongan = event.target.value;
        this.setState({selectedVoucher: obEditVoucher});
    }

    handleKuotaChange = (event) => {
        let obEditVoucher = {...this.state.selectedVoucher};
        obEditVoucher.kuota = event.target.value;
        this.setState({selectedVoucher: obEditVoucher});
    }
    
    handleStartDateChange = (event) => {
        let obEditVoucher = {...this.state.selectedVoucher};
        obEditVoucher.start_date = event.target.value;
        console.log("OBEDIT", obEditVoucher);
        this.setState({selectedVoucher: obEditVoucher});
    }
    
    handleEndDateChange = (event) => {
        let obEditVoucher = {...this.state.selectedVoucher};
        obEditVoucher.end_date = event.target.value;
        console.log("OBEDIT", obEditVoucher);
        this.setState({selectedVoucher: obEditVoucher});
    }

    handleMinTransChange = (event) => {
        let obEditVoucher = {...this.state.selectedVoucher};
        obEditVoucher.min_trans = event.target.value;
        this.setState({selectedVoucher: obEditVoucher});
    }

    handleCheckboxMinimalTransaksiChanged = (event) => {
        let obSelected = {...this.state.selectedVoucher};
        obSelected.minimalTransaksiChecked = event.target.checked;
        if(obSelected.minimalTransaksiChecked !== true){
            obSelected.minimalTransaksiValue = 0;
        }
        this.setState({selectedVoucher: obSelected}, () => {
            console.log("CHECKED", this.state.selectedVoucher);
        });
    }

    handleSave = () => {
        if(this.state.selectedVoucher._id === "" || this.state.selectedVoucher._id === undefined){
            this.addVoucher();
        }else{
            this.updateVoucher();
        }
    }

    list(anchor,){
        return this.state.selectedVoucher === null ? (<></>) : (
        <div className="info" role="presentation"
        //   onClick={this.toggleDrawer(anchor, false)}
        //   onKeyDown={this.toggleDrawer(anchor, false)}
        >
          <div className="container">
              <div className="row">
                    <div className="col-11">
                        <h1>
                            {this.state.selectedVoucher._id === "" || this.state.selectedVoucher._id === undefined ? "Buat Gift Card" : "Edit Gift Card"}
                        </h1>
                    </div>
                   
                  <div className="col-1">
                      <CloseRounded onClick={this.toggleDrawer(false, {})} />
                  </div>
              </div>
              <br />
              {
                this.state.errorMessage.length > 0 ? (
                    <div>
                        <Alert severity="error">{this.state.errorMessage}</Alert>
                    </div>
                ) : (<></>)
              }
                <div className="row" style={{marginTop: 15}}>
                    <div className="col-12">
                        <TextField id="voucherCode" onChange={this.handleVoucherCodeChange} value={this.state.selectedVoucher.voucher_code} label="Kode Gift Card" type="search" variant='standard' fullWidth/>
                    </div>
                </div>
                <div className="row" style={{marginTop: 15}}>
                    <div className="col-12">
                        <TextField id="namaVoucher" onChange={this.handleNamaChange} value={this.state.selectedVoucher.nama} label="Nama Gift Card" type="search" variant='standard' fullWidth/>
                    </div>
                </div>
                <div className="row" style={{marginTop: 15}}>
                    <div className="col-12">
                        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="potonganVoucher">Potongan Gift Card</InputLabel>
                            <Input
                                id="potonganVoucher"
                                value={this.state.selectedVoucher.potongan}
                                onChange={this.handlePotonganChange}
                                startAdornment={<InputAdornment position="start">Rp</InputAdornment>}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row" style={{marginTop: 15}}>
                    <div className="col-12">
                            <TextField id="kuotaVoucher" onChange={this.handleKuotaChange} value={this.state.selectedVoucher.kuota} label="Jumlah Gift Card" type="search" variant='standard' fullWidth/>
                        </div>
                    </div>
                <div className="row" style={{marginTop: 15}}>
                    <div className="col-5">
                        <small>Start</small><br />
                        <TextField onChange={this.handleStartDateChange} value={moment(this.state.selectedVoucher.start_date).format("YYYY-MM-DD")} id="startDate" label="" type="date" variant='standard' fullWidth/>
                    </div>
                    <div className="col-2 text-center" style={{paddingTop: "20px"}}> - </div>
                    <div className="col-5">
                        <small>End</small><br />
                        <TextField onChange={this.handleEndDateChange} value={moment(this.state.selectedVoucher.end_date).format("YYYY-MM-DD")} id="endDate" label="" type="date" variant='standard' fullWidth/>
                    </div>
                </div>
                <div className="row" style={{marginTop: 15}}>
                    <div className="col-12">
                        <FormControlLabel control={<Checkbox checked={this.state.selectedVoucher.minimalTransaksiChecked} onChange={this.handleCheckboxMinimalTransaksiChanged} />} label="Minimal Transaksi" />
                {
                    this.state.selectedVoucher.minimalTransaksiChecked ? (
                        
                        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                            <Input
                                id="minTrans"
                                value={this.state.selectedVoucher.min_trans}
                                onChange={this.handleMinTransChange}
                                startAdornment={<InputAdornment position="start">Rp</InputAdornment>}
                            />
                        </FormControl>
                            
                    ) : (<></>)
                } 
                    </div>
                </div>
                
                <div className="row" style={{marginTop: 15}}>
                    <div className="col-12">
                        <div onClick={this.handleSave} className="btn btn-primary btn-block">Simpan</div>
                    </div>
                </div>
          </div>
          
        </div>
      );
    }

    daftarPelanggan = () => {
        return (
            this.state.selectedVoucher !== null && this.state.selectedVoucher !== undefined ? <Paper className="info" role="presentation" style={{margin: 10}}>
                <div className="row">
                    <div className="col-11">
                        <h4>
                            Daftar Pengguna
                        </h4>
                    </div>
                   
                  <div className="col-1">
                      <CloseRounded onClick={this.toggleDrawer(false, {})} />
                  </div>
              </div>
              <br />
              <div style={{marginBottom: 5}}>
                <strong>Kode Gift Card</strong> : {this.state.selectedVoucher.voucher_code}
              </div>
              <div style={{marginBottom: 5}}>
                <strong>Nama Gift Card</strong> : {this.state.selectedVoucher.nama}
              </div>
              <TableContainer component={Container} style={{marginTop: 20}}>
                <Table sx={{ minWidth: 145 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width={10} align="center" style={{fontWeight: "bold"}}>No</TableCell>
                            <TableCell width={45} align="center" style={{fontWeight: "bold"}}>ID Transaksi</TableCell>
                            <TableCell width={45} align="center" style={{fontWeight: "bold"}}>Nama User</TableCell>
                            <TableCell width={45} align="center" style={{fontWeight: "bold"}}>Tanggal</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.selectedVoucher !== null && this.state.selectedVoucher.history !== undefined && this.state.selectedVoucher.history !== null && this.state.selectedVoucher.history.map((row, index) => (
                        <TableRow
                            key={row._id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>{index+1}</TableCell>
                            <TableCell align="left">{row.transaksi_id}</TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{moment(row.created_at).format("DD/MM/YYYY")}</TableCell>
                            {/* <TableCell align="left">{row.kuota}</TableCell>
                            <TableCell align="center">{row.potongan}</TableCell>
                            <TableCell align="center">{row.min_trans}</TableCell>
                            <TableCell align="center">{row.status}</TableCell>
                            <TableCell align="center" style={{maxWidth: "8vw", minWidth: "8vw"}}>{this.action(row)}</TableCell> */}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Paper> : (<></>)
        );
    }

    getFilterClasses = (role) => {
        let classes = "col-3";
        if(role === this.state.status){
            classes += " filter-item-active";
        }else{
            classes += " filter-item";
        }

        return classes;
    }

    handleChangeFilter = (status) => {
        console.log("STATUS : ", status);

        if(status === this.STATUS_REJECT){
            // const columns = [...this.state.columns];
            // columns.
        }

        this.setState({status: status}, () => {
            this.loadListVoucher();
        });
    }

    toggleActionButton = (voucherInfo) => {
        this.setState({expandedActionId: voucherInfo._id}, () => {
            console.log("TOGGLE", voucherInfo);
        });
    }

    render() { 
        return ( 
            <div>
                {['left', 'right', 'top', 'bottom'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        {/* <Button onClick={this.toggleDrawer(true)}>{anchor}</Button> */}
                        <Drawer anchor={anchor} open={this.state[anchor]} 
                            onClose={this.toggleDrawer(false,{})}
                            >
                            { this.state.drawerMode === this.MODE_FORM ? this.list(anchor) : this.daftarPelanggan()}
                        </Drawer>
                    </React.Fragment>
                ))}

                <Dialog
                    open={this.state.showQr}
                    fullScreen={false}
                    maxWidth={"xl"}
                    onClose={this.handleHideQr}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">
                        {/* {this.state.selectedVoucher?.nama} */}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {/* <img src={this.state.qr} />
                            <QrPdf /> */}
                            <VoucherPdf voucherInfo={this.state.selectedVoucher} qr={this.state.qr}/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={this.handleDownloadQr}>Download</Button> */}
                        <Button onClick={this.handleHideQr} autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <div className="container-fluid no-padding">
                    <div className="container-fluid header-page">
                        {/* <h2 className="mt-4">Subscription Monitoring </h2> */}
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <TextField 
                                    onChange={this.handleKeywordChange}
                                    id="outlined-basic" 
                                    value={this.state.keyword} 
                                    fullWidth 
                                    label="Pencarian" variant="outlined" 
                                />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 text-right">
                                {/* <ExportTransaksi data={this.state.data} />&nbsp;
                                <span className="btn btn-primary" onClick={this.toggleDrawer(true, {})}>Tambah</span> */}
                                <div className="row filter-container">
                                    <div onClick={() => this.handleChangeFilter("all")} className={this.getFilterClasses("all")}>
                                        All
                                    </div>
                                    <div onClick={() => this.handleChangeFilter(this.STATUS_ACTIVE)} className={this.getFilterClasses(this.STATUS_ACTIVE)}>
                                        Aktif
                                    </div>
                                    <div onClick={() => this.handleChangeFilter(this.STATUS_SOLD_OUT)} className={this.getFilterClasses(this.STATUS_SOLD_OUT)}>
                                        Habis
                                    </div>
                                    <div onClick={() => this.handleChangeFilter(this.STATUS_INACTIVE)} className={this.getFilterClasses(this.STATUS_INACTIVE)}>
                                        Tidak Aktif
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid dashboard-content">
                        <TableContainer component={Paper} style={{marginTop: 20}}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{fontWeight: "bold"}}>Kode Gift Card</TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}>Nama Gift card</TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}>Periode</TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}>Kuota</TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}>Sisa</TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}>Potongan</TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}>Min Trans</TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold"}}>Status</TableCell>
                                        <TableCell align="center" style={{fontWeight: "bold", maxWidth: "5vw", minWidth: "5vw"}}>
                                        <ButtonGroup
                                            orientation="vertical"
                                            aria-label="vertical contained button group"
                                            variant="contained"
                                        >
                                            <Button onClick={this.toggleDrawer(true, this.defaultSelectedVoucher(), this.MODE_FORM)} key="one" style={{fontSize: 10}}>+ Buat Gift Card</Button>
                                        </ButtonGroup>
                                            {/* <div className="btn btn-primary btn-block">+ Buat Voucher</div> */}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.data !== null && this.state.data.map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.voucher_code}</TableCell>
                                        <TableCell align="left">{row.nama}</TableCell>
                                        <TableCell align="left">{Global.formatDateTimeToStrFormatted(row.start_date,"DD/MM/YYYY")} - {Global.formatDateTimeToStrFormatted(row.end_date, "DD/MM/YYYY")}</TableCell>
                                        <TableCell align="left">{row.kuota}</TableCell>
                                        <TableCell align="left">{row.kuota-row.totalPengguna}</TableCell>
                                        <TableCell align="center">{row.potongan}</TableCell>
                                        <TableCell align="center">{row.min_trans}</TableCell>
                                        <TableCell align="center">{row.status}</TableCell>
                                        <TableCell align="center" style={{maxWidth: "8vw", minWidth: "8vw"}} onClick={() => {}}>{this.action(row)}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>
                </div>
            </div>
        );
    }
}
 
export default VoucherDiscount;

const QrPdf = () => {
    return (
        <Document>
            {/* <Page size="A4" style={styles.page}> */}
            <Page size="A4">
            {/* <View style={styles.section}> */}
            <View>
                <Text>Section #1</Text>
            </View>
            {/* <View style={styles.section}> */}
            <View>
                <Text>Section #2</Text>
            </View>
            </Page>
        </Document>
    );
}