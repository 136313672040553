import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { Article, ImportExport } from "@mui/icons-material";
import moment from "moment";
import { Button } from '@mui/material';
import Global from "../../lib/Global";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportStores extends Component {

    getDatas = () => {
        let leadsData = [];
        this.props.data.forEach(element => {
            element.formatted_join_date = Global.formatDateTimeToStrDateTime24(element.join_date);
            element.formatted_last_transaction = element.transaksi ? Global.formatDateTimeToStrDateTime24(element.transaksi) : "Belum ada";

            let lastSubs = element.last_subscription;
            let period = "";
            if(lastSubs && lastSubs.length > 0){
                let last = lastSubs[lastSubs.length - 1];
                period = last.subscription_period;
            }
            let formattedSubscription = `${element.subscribe_type}-${element.trial ? 'trial' : 'paid'}-${period}`;
            element.formatted_subscription = formattedSubscription;
            
            let formattedAkhirSubscription = element.akhir_subscription ? Global.formatDateTimeToStrDateTime24(element.akhir_subscription) : "Invalid Date"
            element.formatted_akhir_subscription = formattedAkhirSubscription;
            leadsData.push(element);
        });
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<Button variant='text' size='small' color='primary'>Export to XLSX<Article /></Button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getDatas} name="Store Data">
                    <ExcelColumn label="Nama Toko" value="nama_toko"/>
                    <ExcelColumn label="Pemilik" value="owner_name"/>
                    <ExcelColumn label="No HP" value="owner_ussd"/>
                    <ExcelColumn label="Lokasi" value="alamat"/>
                    <ExcelColumn label="Join Date" value="formatted_join_date"/>
                    <ExcelColumn label="Transaksi Terakhir" value="formatted_last_transaction" />
                    <ExcelColumn label="Subscription" value="formatted_subscription"/>
                    <ExcelColumn label="End On" value="formatted_akhir_subscription"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportStores;