import moment from "moment";

class Global {
  _data = [];
  waMessageTemplate = {
      template: {}
  };

  socketData = {
    socket: {}
  };

  add(item) {
    this._data.pop();
    this._data.push(item);
  }

  get() {
    return this._data.length > 0 ? this._data[0] : null;
  }

  /** Format datetime to String Date And Time 24 Hours Format */
	formatDateTimeToStrDateTime24 = (datetime, isExcludeSeconds = true) => {

		let strFormat = !isExcludeSeconds ? "DD-MM-Y HH:mm:ss" : "DD-MM-Y HH:mm";

		let formatted = moment(datetime).tz("asia/jakarta").format(strFormat);
		return formatted;
	}

  /** Format datetime to String Formatted */
	formatDateTimeToStrFormatted = (datetime, strFormat) => {

		let formatted = moment(datetime).tz("asia/jakarta").format(strFormat);
		return formatted;
	}

}

const instance = new Global();
Object.freeze(instance);

export default instance;
