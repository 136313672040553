import { DoneAll, Error, Image, ImageOutlined } from '@mui/icons-material';
import axios from 'axios';
import React, { Component, useState } from 'react';
import Api from "../../../lib/Api";
import Global from "../../../lib/Global";
import moment from 'moment/moment';
import DownloadTemplateProductBulk from '../DownloadTemplateProduct';
import { FileUploader } from 'react-drag-drop-files';
import { Button, LinearProgress } from '@mui/material';

function DialogStoreInfoContent(props) {

    const [file, setFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

    const dropArea = () => {
        return (
        <div>
            {
                file === null && <div className='text-center' style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    <ImageOutlined /><br /><br /><h5 className='text-primary'>Upload File Template</h5>Browse atau Drop file template produk disini
                </div>
            }

            {
                file !== null && <div className='text-center' style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    <Image className='text-success' /><br />{file.name}
                </div>
            }
        </div>
        );
    }

    const handleFileChange = (file) => {
        setFile(file);
    }

    const uploadTemplateProductBulk = () => {
        const data = new FormData();
        data.append("items", file);
        setIsSubmitting(true);
        axios
            .post(Api.uploadProductBulk(), data, Api.getRequestHeader(true))
            .then((response) => {
                console.log("RESPONSE_AXIOS", response.data);
                if (response.status === 200 || response.status === 201) {
                    setIsSubmittedSuccessfully(true);
                }else{
                    setIsSubmittedSuccessfully(false);
                }
                
                setIsSubmitting(false);
            })
            .catch((error) => {
                setIsSubmitting(false);
                setIsSubmittedSuccessfully(false);
                setErrorMessage(error.message);
            });
    }

    const clearSelectedFile = () => {
        setFile(null);
    }

    return <>
        <div className="row">
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Nama Toko</div>
                    <div className="col-8">: {props.storeInfo?.nama_toko}
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Store ID</div>
                    <div className="col-8">: <strong>{props.storeInfo?.id}</strong></div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Pemilik</div>
                    <div className="col-8">: {props.storeInfo?.owner_name}</div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Join Date</div>
                    <div className="col-8">: {Global.formatDateTimeToStrDateTime24(props.storeInfo?.created_at)}</div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <div className="row">
                    <div className="col-4">No. Handphone</div>
                    <div className="col-8">: {props.storeInfo?.owner_ussd}</div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">last Activity</div>
                    <div className="col-8">: {Global.formatDateTimeToStrDateTime24(props.storeInfo?.last_transaction)}</div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Domisili</div>
                    <div className="col-8">: {props.storeInfo?.alamat}</div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Jenis Subscription</div>
                    <div className="col-8">: {props.storeInfo?.subscribe_type}</div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Referal</div>
                    <div className="col-8">: -</div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <div className="col-4">Akhir Subscription</div>
                    <div className="col-8">: {Global.formatDateTimeToStrDateTime24(props.storeInfo?.valid_thru)}</div>
                </div>
            </div>
        </div>
        <hr />
        <h3 className='text-primary'>Upload Produk</h3>
        <p className='text-secondary'>
            Upload produk secara bulk memungkinkan admin mengupload daftar produk dalam jumlah besar menggunakan file Excel yang divalidasi oleh sistem.
            Produk ditambahkan ke toko jika file valid, atau notifikasi error diberikan jika terjadi kesalahan 
        </p>
        <DownloadTemplateProductBulk storeId={props.storeInfo.id} />
        <br />
        <br />
        
        {
            file === null && (<FileUploader 
                classes={"container border rounded"}
                children={dropArea()} 
                multiple={false} 
                handleChange={handleFileChange}  
                types={["XLS", "XLSX"]} 
            />)
        }

        {
            (file !== null && !isSubmittedSuccessfully) && (
                <div className='container border rounded text-center' style={{padding: "20px"}}>
                    <h4>{file.name}</h4>
                    <Button disabled={isSubmitting} variant='contained' color='primary' style={{marginRight: "5px"}} onClick={uploadTemplateProductBulk}>
                        Submit
                    </Button>
                    <Button disabled={isSubmitting} variant='contained' color='error' style={{marginLeft: "5px"}} onClick={clearSelectedFile}>
                        Batalkan
                    </Button>
                    {
                        isSubmitting && <div className='text-center' style={{marginTop: "20px"}}>
                            Uploading ...
                            <LinearProgress />
                        </div>
                    }

                    {
                        errorMessage.length > 0 && <div className='text-center text-danger' style={{marginTop: "20px"}}>
                        <Error /><br />
                        {errorMessage}
                    </div>
                    }
                </div>
            )
        }

        {
            isSubmittedSuccessfully && (
                <div className='container border rounded text-center bg-success text-center text-white' style={{padding: "20px"}}>
                    <DoneAll /><br />
                    Product berhasil diupload!
                </div>
            )
        }

        
    </>;
}

export default DialogStoreInfoContent;