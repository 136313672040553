import React, { Component } from 'react';
import ReactExport from "react-export-excel-xlsx-fix";
import { Article, ImportExport, Note } from "@mui/icons-material";
import moment from "moment";
import { Button } from '@mui/material';
import Global from "../../lib/Global";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportDataSubscription extends Component {

    getUserDatas = () => {
        let leadsData = [];
        this.props.userData.forEach(element => {
            element.formatted_tanggal_pembelian = Global.formatDateTimeToStrDateTime24(element.created_at);
            element.user_name = element.store && element.store.length > 0 ? element.store[0].owner_name : "";
            element.store_name = element.store && element.store.length > 0 ? element.store[0].nama_toko : "";
            element.formatted_status = (element.status ?? "").toUpperCase();
            element.voucher = element.gift_card ? element.gift_card.nama : "";
            element.device = element.metode !== "IOS" ? "Android" : element.metode;
            element.nominal = new Intl.NumberFormat('id-ID', {  }).format(element.nominal,);
            leadsData.push(element);
        });
        return leadsData;
    }

    state = {  }
    render() { 
        return (
            <ExcelFile element={<Button variant='text' size='small' color='primary'>Export to XLSX<Article /></Button>}>
            {/* <ExcelFile> */}
                <ExcelSheet data={this.getUserDatas} name="User Data">
                    <ExcelColumn label="ID Pembelian" value="id"/>
                    <ExcelColumn label="Tanggal Pembelian" value="formatted_tanggal_pembelian"/>
                    <ExcelColumn label="Jenis Paket" value="subscription_type"/>
                    <ExcelColumn label="User" value="user_name"/>
                    <ExcelColumn label="Toko" value="store_name"/>
                    <ExcelColumn label="Status" value="formatted_status" />
                    <ExcelColumn label="Metode Pembayaran" value="metode" />
                    <ExcelColumn label="Voucher" value="voucher" />
                    <ExcelColumn label="Device" value="device" />
                    <ExcelColumn label="Nominal" value="nominal" />
                    
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
 
export default ExportDataSubscription;