import { CloseRounded, Delete, Edit } from '@mui/icons-material';
import { Divider } from '@mui/material';
import React, { Component } from 'react';
import moment from "moment";
import Global from "../../lib/Global";

class PendingNotification extends Component {
    state = {  } 

    
    render() { 
        return (
            <div className='container-fluid' style={{minWidth:"500px", maxWidth: "550px", padding: "10px"}}>
                <br />
                <div className='text-right'>
                    <CloseRounded onClick={this.props.onCloseDrawer()} style={{cursor: "pointer"}} />
                </div>
                <h3>Kirim Info</h3>
                <br />
                <div className="container border">
                    
                {
                    this.props.pendingNotificationsData.map((row) => (
                        <div style={{marginBottom: "20px"}}>
                            <div className="row" style={{paddingTop: "8px"}}>
                                <div className="col-10 text-secondary">
                                    Dibuat {Global.formatDateTimeToStrDateTime24(row.created_at)}
                                </div>
                                <div className="col-2 text-secondary">
                                    {/* <span className='btn btn-sm btn-outline-secondary' style={{border: "solid 1px #DEDEDD", borderRadius: "400px", padding: "3px"}}>
                                        <Edit style={{width: "15px", height: "15px"}} />
                                    </span> */}
                                    &nbsp;
                                    <span onClick={() => this.props.onDeleteNotification(row)} className='btn btn-sm btn-outline-secondary' style={{border: "solid 1px #DEDEDD", borderRadius: "400px", padding: "3px"}}>
                                        <Delete style={{width: "15px", height: "15px"}}/>
                                    </span>
                                </div>
                            </div>
                            {/* <Divider style={{marginTop: "10px", marginBottom: "10px"}} /> */}
                            <div className="row">
                                <div className="col-12">
                                    <strong>{row.title}</strong>
                                    <br />
                                    {row.content}
                                </div>
                            </div>
                            {/* <Divider style={{marginTop: "10px", marginBottom: "10px"}} /> */}
                            <div className="row">
                                <div className="col-12 text-secondary">
                                    Akan dikirim {row.schedule.date} {row.schedule.time}
                                </div>
                            </div>
                            <Divider style={{marginTop: "10px", marginBottom: "10px"}} />
                        </div>
                    ))
                }

                {
                    this.props.pendingNotificationsData.length <= 0 ? 
                        (
                            <div className='text-center'>
                                <span style={{padding: "10px"}}>Belum ada notifikasi terjadwal</span>
                            </div>
                        ) : (<></>)
                }

                    
                </div>
            </div>
        );
    }
}
 
export default PendingNotification;